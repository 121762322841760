import React, { useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { withTranslation } from "react-i18next";
import { uploadImage } from "apis/systemEmails";
import { connect } from "react-redux";
    

const CustomEditor = (props) => {
  const [editorData, setEditorData] = useState("");
  const prevContentRef = useRef(editorData); // To store previous content for comparison

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then(async (file) => {
            formData.append("upload", file);
            try {
              const result = await uploadImage(formData, props.id);
              resolve({
                default: result.url,
              });
            } catch (error) {
              reject(error);
            }
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
    
    // when upload completes, replace tag
    const imageUploadEditing = editor.plugins.get("ImageUploadEditing");
    imageUploadEditing.on("uploadComplete", (evt, { data, imageElement }) => {
      editor.model.change((writer) => {
        const view = editor.data.processor.toView(
          data.mediaType === "video"
            ? `<video src='${data.default}' controls="controls"></video>`
            : data.mediaType === "audio"
              ? `<audio src='${data.default}' controls="controls"></audio>`
              : `<img src='${data.default}' />`
        );
        const model = editor.data.toModel(view);
        editor.model.insertContent(model, editor.model.document.selection);
      });
    
      evt.stop();
      editor.editing.view.focus();
    });
  }

  const handleEditorChange = (event, editor) => {
    const currentContent = editor.getData();
    setEditorData(currentContent);

    const prevContent = prevContentRef.current;

    const prevImages = extractImageUrls(prevContent);
    const currentImages = extractImageUrls(currentContent);

    prevImages.forEach((prevImageUrl) => {
      if (!currentImages.includes(prevImageUrl)) {
        props.onImageRemove(prevImageUrl);
      }
    });

    prevContentRef.current = currentContent;
    props.onChange(event, editor);
  };

  const extractImageUrls = (content) => {
    const imageUrls = [];
    const regex = /<img src="(.*?)"/g;
    let match;
    while ((match = regex.exec(content)) !== null) {
      imageUrls.push(match[1]);
    }
    return imageUrls;
  };


  const editorConfiguration = {
    placeholder: "write your content...",
    extraPlugins: [uploadPlugin],
    image: {
      upload: { types: ["mp4", "pdf", "mpeg", "jpg", "png"] },
    },
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true,
        },
      ],
    },
  };
  return (
    <CKEditor
      editor={ ClassicEditor }
      placeholder={props.t("Enter Email Content")}
      data={props.data}
      config={editorConfiguration}
      onChange={handleEditorChange}  
      onImageRemove={props.onImageRemove}
    />
  );
};

export default connect(null, null)(withTranslation()(CustomEditor));